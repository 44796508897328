.signup-wrapper{
    width: 100%;
    max-width: 400px;
    transform: translateY(-10%);
}
.signup-paper{
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.signup-title{
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0;
}
.signup-description{
    font-size: 1rem;
    margin-bottom: 2rem;
}
.signup-have-an-account{
    font-size: 0.7rem;
    margin-top: 0.5em;
    margin-bottom: 0;
    text-align: center;
}

.PhoneInputInput{
    border: 0;
    outline: none;
    font-size: 1rem;
}

.PhoneInputCountrySelect{
    font-size: 1rem;
}

.PhoneInputCountryIcon--border{
    box-shadow: none !important;
    outline: none !important;
}