.thank-you-wrapper{
    width: 100%;
    max-width: 400px;
    transform: translateY(-25%);
}
.thank-you-paper{
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.thank-you-title{
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0;
}
.thank-you-description{
    font-size: 1rem;
    margin-bottom: 2rem;
}

.thank-you-redirect-notice{
    font-size: 0.7rem;
    margin-top: 0.5em;
    margin-bottom: 0;
    text-align: center;
}